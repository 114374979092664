// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/login","layout":false,"id":"1"},"2":{"path":"/chat/share","layout":false,"id":"2"},"3":{"path":"","layout":false,"parentId":"4","id":"3","originPath":"/"},"4":{"path":"/","isWrapper":true,"layout":false,"id":"4"},"5":{"path":"/","redirect":"/chat","parentId":"3","id":"5"},"6":{"path":"/knowledge","parentId":"3","id":"6"},"7":{"path":"/teams","parentId":"3","id":"7"},"8":{"path":"/models","parentId":"3","id":"8"},"9":{"path":"/manage-teams","parentId":"3","id":"9"},"10":{"path":"/knowledge","parentId":"3","id":"10"},"11":{"path":"/knowledge/dataset","parentId":"10","id":"11"},"12":{"path":"/knowledge/dataset","parentId":"11","id":"12"},"13":{"path":"/knowledge/dataset/chunk","parentId":"11","id":"13"},"14":{"path":"/knowledge/configuration","parentId":"10","id":"14"},"15":{"path":"/knowledge/testing","parentId":"10","id":"15"},"16":{"path":"/chat","parentId":"3","id":"16"},"17":{"path":"/user-setting","parentId":"3","id":"17"},"18":{"path":"/user-setting","redirect":"/user-setting/profile","parentId":"17","id":"18"},"19":{"path":"/user-setting/profile","parentId":"17","id":"19"},"20":{"path":"/user-setting/password","parentId":"17","id":"20"},"21":{"path":"/user-setting/model","parentId":"17","id":"21"},"22":{"path":"/user-setting/team","parentId":"17","id":"22"},"23":{"path":"/user-setting/system","parentId":"17","id":"23"},"24":{"path":"/file","parentId":"3","id":"24"},"25":{"path":"/flow","parentId":"3","id":"25"},"26":{"path":"/flow/:id","parentId":"3","id":"26"},"27":{"path":"document/:id","layout":false,"id":"27"},"28":{"path":"force","layout":false,"id":"28"},"29":{"path":"/*","layout":false,"id":"29"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "p__login__index" */'@/pages/login/index.tsx')),
'2': React.lazy(() => import(/* webpackChunkName: "p__chat__share__index" */'@/pages/chat/share/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'@/layouts/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "wrappers__auth" */'@/wrappers/auth.tsx')),
'5': React.lazy(() => import('./EmptyRoute')),
'6': React.lazy(() => import(/* webpackChunkName: "p__knowledge__index" */'@/pages/knowledge/index.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__manage-teams__index" */'@/pages/manage-teams/index.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__manage-models__index" */'@/pages/manage-models/index.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__manage-teams__team-manage-users__index" */'@/pages/manage-teams/team-manage-users/index.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__add-knowledge__index" */'@/pages/add-knowledge/index.tsx')),
'11': React.lazy(() => import(/* webpackChunkName: "p__add-knowledge__components__knowledge-dataset__index" */'@/pages/add-knowledge/components/knowledge-dataset/index.tsx')),
'12': React.lazy(() => import(/* webpackChunkName: "p__add-knowledge__components__knowledge-file__index" */'@/pages/add-knowledge/components/knowledge-file/index.tsx')),
'13': React.lazy(() => import(/* webpackChunkName: "p__add-knowledge__components__knowledge-chunk__index" */'@/pages/add-knowledge/components/knowledge-chunk/index.tsx')),
'14': React.lazy(() => import(/* webpackChunkName: "p__add-knowledge__components__knowledge-setting__index" */'@/pages/add-knowledge/components/knowledge-setting/index.tsx')),
'15': React.lazy(() => import(/* webpackChunkName: "p__add-knowledge__components__knowledge-testing__index" */'@/pages/add-knowledge/components/knowledge-testing/index.tsx')),
'16': React.lazy(() => import(/* webpackChunkName: "p__chat__index" */'@/pages/chat/index.tsx')),
'17': React.lazy(() => import(/* webpackChunkName: "p__user-setting__index" */'@/pages/user-setting/index.tsx')),
'18': React.lazy(() => import('./EmptyRoute')),
'19': React.lazy(() => import(/* webpackChunkName: "p__user-setting__setting-profile__index" */'@/pages/user-setting/setting-profile/index.tsx')),
'20': React.lazy(() => import(/* webpackChunkName: "p__user-setting__setting-password__index" */'@/pages/user-setting/setting-password/index.tsx')),
'21': React.lazy(() => import(/* webpackChunkName: "p__user-setting__setting-model__index" */'@/pages/user-setting/setting-model/index.tsx')),
'22': React.lazy(() => import(/* webpackChunkName: "p__user-setting__setting-team__index" */'@/pages/user-setting/setting-team/index.tsx')),
'23': React.lazy(() => import(/* webpackChunkName: "p__user-setting__setting-system__index" */'@/pages/user-setting/setting-system/index.tsx')),
'24': React.lazy(() => import(/* webpackChunkName: "p__file-manager__index" */'@/pages/file-manager/index.tsx')),
'25': React.lazy(() => import(/* webpackChunkName: "p__flow__list__index" */'@/pages/flow/list/index.tsx')),
'26': React.lazy(() => import(/* webpackChunkName: "p__flow__index" */'@/pages/flow/index.tsx')),
'27': React.lazy(() => import(/* webpackChunkName: "p__document-viewer__index" */'@/pages/document-viewer/index.tsx')),
'28': React.lazy(() => import(/* webpackChunkName: "p__force-graph__index" */'@/pages/force-graph/index.tsx')),
'29': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.jsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/ragflow/web/src/layouts/index.tsx')),
},
  };
}
