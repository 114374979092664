export default {
  translation: {
    common: {
      delete: 'Supprimer',
      deleteModalTitle: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
      ok: 'Oui',
      cancel: 'Non',
      total: 'Total',
      rename: 'Renommer',
      name: 'Nom',
      save: 'Enregistrer',
      namePlaceholder: 'Veuillez saisir le nom',
      next: 'Suivant',
      create: 'Créer',
      edit: 'Modifier',
      upload: 'Téléverser',
      english: 'Anglais',
      french: 'Français',
      chinese: 'Chinois',
      traditionalChinese: 'Chinois traditionnel',
      language: 'Langue',
      languageMessage: 'Veuillez saisir votre langue !',
      languagePlaceholder: 'sélectionnez votre langue',
      copy: 'Copier',
      copied: 'Copié',
      comingSoon: 'À venir',
      download: 'Télécharger',
      close: 'Fermer',
      preview: 'Aperçu',
      move: 'Déplacer',
      change: 'Changer de rôle',
    },
    login: {
      login: 'Se connecter',
      signUp: 'S’inscrire',
      loginDescription: 'Nous sommes ravis de vous revoir !',
      registerDescription: 'Heureux de vous avoir à bord !',
      emailLabel: 'Email',
      emailPlaceholder: 'Veuillez saisir l’email',
      passwordLabel: 'Mot de passe',
      passwordPlaceholder: 'Veuillez saisir le mot de passe',
      rememberMe: 'Se souvenir de moi',
      signInTip: 'Vous n’avez pas de compte ?',
      signUpTip: 'Vous avez déjà un compte ?',
      nicknameLabel: 'Surnom',
      nicknamePlaceholder: 'Veuillez saisir le surnom',
      register: 'Créer un compte',
      continue: 'Continuer',
      title: 'Commencez à créer vos assistants intelligents.',
      description:
        'Inscrivez-vous gratuitement pour explorer la meilleure technologie RAG. Créez des bases de connaissances et des IA pour dynamiser votre entreprise.',
      review: 'à partir de 500+ avis',
    },
    header: {
      knowledgeBase: 'Base de connaissances',
      chat: 'Discussion',
      register: 'S’inscrire',
      signin: 'Se connecter',
      home: 'Accueil',
      setting: 'Paramètres utilisateur',
      logout: 'Déconnexion',
      fileManager: 'Gestion des fichiers',
      manageTeams: 'Gestion des équipes',
      flow: 'Agent',
    },
    knowledgeList: {
      welcome: 'Bienvenue de nouveau',
      description:
        "Quelle base de connaissances allons-nous utiliser aujourd'hui ?",
      createKnowledgeBase: 'Créer une base de connaissances',
      name: 'Nom',
      namePlaceholder: 'Veuillez entrer un nom !',
      doc: 'Documents',
      teamName: 'équipe',
      ownTeam: 'Perso',
      searchKnowledgePlaceholder: 'Rechercher',
    },
    knowledgeDetails: {
      dataset: 'Jeu de données',
      testing: 'Test de récupération',
      files: 'fichiers',
      configuration: 'Configuration',
      name: 'Nom',
      namePlaceholder: 'Veuillez entrer un nom !',
      doc: 'Documents',
      datasetDescription:
        "😉 Les questions et réponses ne peuvent être répondues qu'après que l'analyse soit réussie.",
      addFile: 'Ajouter un fichier',
      searchFiles: 'Rechercher vos fichiers',
      localFiles: 'Fichiers locaux',
      emptyFiles: 'Créer un fichier vide',
      webCrawl: 'Exploration web',
      chunkNumber: 'Numéro de segment',
      uploadDate: 'Date de téléchargement',
      chunkMethod: 'Méthode de segmentation',
      enabled: 'Activer',
      disabled: 'Désactiver',
      action: 'Action',
      parsingStatus: "État de l'analyse",
      processBeginAt: 'Début du traitement à',
      processDuration: 'Durée du traitement',
      progressMsg: 'Message de progression',
      testingDescription:
        'Dernière étape ! Après succès, laissez le reste à Infiniflow AI.',
      similarityThreshold: 'Seuil de similarité',
      similarityThresholdTip:
        "Nous utilisons un score de similarité hybride pour évaluer la distance entre deux lignes de texte. C'est la similarité des mots pondérés et la similarité cosinus des vecteurs. Si la similarité entre la requête et le segment est inférieure à ce seuil, le segment sera filtré.",
      vectorSimilarityWeight: 'Poids de similarité des mots',
      vectorSimilarityWeightTip:
        "Nous utilisons un score de similarité hybride pour évaluer la distance entre deux lignes de texte. C'est la similarité des mots pondérés et la similarité cosinus des vecteurs ou le score de reranking (0~1). La somme des deux poids est de 1,0.",
      testText: 'Texte de test',
      testTextPlaceholder: 'Veuillez entrer votre question !',
      testingLabel: 'Test',
      similarity: 'Similarité hybride',
      termSimilarity: 'Similarité des termes',
      vectorSimilarity: 'Similarité vectorielle',
      hits: 'Résultats',
      view: 'Voir',
      filesSelected: 'Fichiers sélectionnés',
      upload: 'Télécharger',
      run: 'Exécuter',
      runningStatus0: 'NON DÉMARRÉ',
      runningStatus1: 'Analyse',
      runningStatus2: 'ANNULÉ',
      runningStatus3: 'SUCCÈS',
      runningStatus4: 'ÉCHEC',
      pageRanges: 'Plages de pages',
      pageRangesTip:
        'Plages de pages : Définissez les plages de pages à analyser. Les pages non incluses dans ces plages seront ignorées.',
      fromPlaceholder: 'de',
      fromMessage: 'Numéro de page de début manquant',
      toPlaceholder: 'à',
      toMessage: 'Numéro de page de fin manquant (exclu)',
      layoutRecognize: 'Reconnaissance de la mise en page',
      layoutRecognizeTip:
        "Utilisez des modèles visuels pour l'analyse de la mise en page afin de mieux identifier la structure du document, trouver où se trouvent les titres, les blocs de texte, les images et les tableaux. Sans cette fonctionnalité, seule le texte brut du PDF peut être obtenu.",
      taskPageSize: 'Taille de la page de tâche',
      taskPageSizeMessage: 'Veuillez entrer la taille de la page de tâche !',
      taskPageSizeTip: `Si vous utilisez la reconnaissance de mise en page, le fichier PDF sera divisé en groupes successifs. L'analyse de la mise en page sera effectuée en parallèle entre les groupes pour augmenter la vitesse de traitement. La 'Taille de la page de tâche' détermine la taille des groupes. Plus la taille de la page est grande, moins il y a de chances que le texte continu entre les pages soit divisé en segments différents.`,
      addPage: 'Ajouter une page',
      greaterThan: 'La valeur actuelle doit être supérieure à à !',
      greaterThanPrevious:
        'La valeur actuelle doit être supérieure à la valeur précédente !',
      selectFiles: 'Sélectionner des fichiers',
      changeSpecificCategory: 'Changer la catégorie spécifique',
      uploadTitle:
        'Cliquez ou faites glisser le fichier dans cette zone pour télécharger',
      uploadDescription:
        "Support pour un téléchargement unique ou en masse. Il est strictement interdit de télécharger des données d'entreprise ou d'autres fichiers interdits.",
      chunk: 'Segment',
      bulk: 'Masse',
      cancel: 'Annuler',
      rerankModel: 'Modèle de reranking',
      rerankPlaceholder: 'Veuillez sélectionner',
      rerankTip: `S'il est vide. Il utilise les embeddings de la requête et des segments pour calculer la similarité cosinus des vecteurs. Sinon, il utilise le score de reranking à la place de la similarité cosinus des vecteurs.`,
      topK: 'Top-K',
      topKTip: `K segments seront envoyés aux modèles de reranking.`,
      delimiter: `Délimiteur`,
    },
    knowledgeConfiguration: {
      titleDescription:
        "Mettez à jour les détails de votre base de connaissances, notamment la méthode d'analyse ici.",
      name: 'Nom de la base de connaissances',
      photo: 'Photo de la base de connaissances',
      description: 'Description',
      language: 'Langue',
      languageMessage: 'Veuillez entrer votre langue !',
      languagePlaceholder: 'Veuillez entrer votre langue !',
      permissions: 'Autorisations',
      embeddingModel: "Modèle d'embedding",
      chunkTokenNumber: 'Nombre de jetons par segment',
      chunkTokenNumberMessage: 'Le nombre de jetons par segment est requis',
      embeddingModelTip:
        "Le modèle d'embedding utilisé pour les segments. Il est inchangeable une fois que la base de connaissances contient des segments. Vous devez supprimer tous les segments si vous souhaitez le changer.",
      permissionsTip:
        "Si l'autorisation est 'Équipe', tous les membres de l'équipe peuvent manipuler la base de connaissances.",
      chunkTokenNumberTip:
        "Il détermine approximativement le nombre de jetons d'un segment.",
      chunkMethod: 'Méthode de segmentation',
      chunkMethodTip: 'Les instructions sont à droite.',
      upload: 'Télécharger',
      english: 'Anglais',
      chinese: 'Chinois',
      french: 'Français',
      embeddingModelPlaceholder: "Veuillez sélectionner un modèle d'embedding",
      chunkMethodPlaceholder:
        'Veuillez sélectionner une méthode de segmentation',
      save: 'Sauvegarder',
      me: 'Seulement moi',
      team: 'Équipe',
      cancel: 'Annuler',
      methodTitle: 'Description de la méthode de segmentation',
      methodExamples: 'Exemples',
      methodExamplesDescription:
        "Les captures d'écran suivantes sont présentées pour faciliter la compréhension.",
      dialogueExamplesTitle: 'Exemples de dialogue',
      methodEmpty:
        'Cela affichera une explication visuelle des catégories de la base de connaissances',
      book: `<p>Les formats de fichiers supportés sont <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
      Étant donné qu'un livre est long et que toutes les parties ne sont pas utiles, s'il s'agit d'un PDF,
      veuillez définir les <i>plages de pages</i> pour chaque livre afin d'éliminer les effets négatifs et de gagner du temps de calcul pour l'analyse.</p>`,
      laws: `<p>Les formats de fichiers supportés sont <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
      Les documents juridiques ont un format d'écriture très rigoureux. Nous utilisons les caractéristiques du texte pour détecter les points de séparation. 
      </p><p>
      La granularité des segments est cohérente avec 'ARTICLE', et tout le texte de niveau supérieur sera inclus dans le segment.
      </p>`,
      manual: `<p>Seul le format <b>PDF</b> est supporté.</p><p>
      Nous supposons que le manuel a une structure hiérarchique des sections. Nous utilisons les titres de section les plus bas comme pivots pour découper les documents.
      Ainsi, les figures et les tableaux dans la même section ne seront pas séparés, et la taille des segments peut être grande.
      </p>`,
      naive: `<p>Les formats de fichiers supportés sont <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML</b>.</p>
      <p>Cette méthode applique des moyens naïfs pour segmenter les fichiers :</p>
      <p>
      <li>Le texte successif sera découpé en morceaux à l'aide d'un modèle de détection visuelle.</li>
      <li>Ensuite, ces morceaux successifs sont fusionnés en segments dont le nombre de jetons ne dépasse pas le 'Nombre de jetons'.</li></p>`,
      paper: `<p>Seul le fichier <b>PDF</b> est supporté.</p><p>
      Si notre modèle fonctionne bien, le document sera découpé par ses sections, comme <i>résumé, 1.1, 1.2</i>, etc. </p><p>
      L'avantage de cela est que le LLM peut mieux résumer le contenu des sections pertinentes du document, 
      ce qui permet de fournir des réponses plus complètes qui aident les lecteurs à mieux comprendre le document. 
      L'inconvénient est que cela augmente le contexte de la conversation LLM et ajoute un coût de calcul, 
      donc pendant la conversation, vous pouvez envisager de réduire le paramètre ‘<b>topN</b>’.</p>`,
      presentation: `<p>Les formats de fichiers supportés sont <b>PDF</b>, <b>PPTX</b>.</p><p>
      Chaque page sera considérée comme un segment. Et la miniature de chaque page sera stockée.</p><p>
      <i>Tous les fichiers PPT que vous avez téléchargés seront automatiquement segmentés en utilisant cette méthode, il n'est pas nécessaire de les configurer pour chaque fichier PPT.</i></p>`,
      qa: `
      <p>
      Cette méthode de segmentation supporte les formats de fichiers <b>EXCEL</b> et <b>CSV/TXT</b>.
      </p>
      <li>
      Si le fichier est au format <b>Excel</b>, il doit comporter deux colonnes
      sans en-têtes : une pour les questions et l'autre pour les réponses, avec
      la colonne des questions précédant la colonne des réponses. Plusieurs 
      feuilles sont acceptables tant que les colonnes sont correctement structurées.
      </li>
      <li>
      Si le fichier est au format <b>CSV/TXT</b>, il doit être encodé en UTF-8 avec TAB utilisé comme délimiteur pour séparer les questions et les réponses.
      </li>
      
      <p>
        <i>
          Les lignes de texte qui ne respectent pas les règles ci-dessus seront
          ignorées, et chaque paire Q&R sera considérée comme un segment distinct.
        </i>
      </p>
      `,
      resume: `<p>Les formats de fichiers supportés sont <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.
      </p><p>
      Le CV se présente sous différents formats, tout comme la personnalité d'une personne, mais nous devons souvent les organiser en données structurées pour faciliter la recherche.
      </p><p>
      Au lieu de segmenter le CV, nous analysons le CV en données structurées. En tant que recruteur, vous pouvez déposer tous les CV que vous avez, 
      puis vous pouvez lister tous les candidats correspondant aux qualifications simplement en discutant avec <i>'RAGFlow'</i>.
      </p>
      `,
      table: `<p>Les formats de fichiers <b>EXCEL</b> et <b>CSV/TXT</b> sont supportés.</p><p>
      Voici quelques conseils :
      <ul>
      <li>Pour les fichiers csv ou txt, le délimiteur entre les colonnes est <em><b>TAB</b></em>.</li>
      <li>La première ligne doit être les en-têtes de colonnes.</li>
      <li>Les en-têtes de colonnes doivent être des termes significatifs afin de faciliter la compréhension par notre LLM.
      Il est bon d'énumérer certains synonymes en utilisant une barre oblique
      <i>'/'</i> pour séparer, et encore mieux d'énumérer les valeurs en utilisant des crochets comme <i>'genre/sexe(masculin, féminin)'</i>.<p>
      Voici quelques exemples d'en-têtes :<ol>
        <li>fournisseur/vendeur<b>'TAB'</b>couleur(jaune, rouge, marron)<b>'TAB'</b>genre/sexe(masculin, féminin)<b>'TAB'</b>taille(S, M, L, XL, XXL)</li>
        <li>Nom/Prénom <b>'TAB'</b> Téléphone/Portable/WeChat <b>'TAB'</b> Plus haut niveau d'études (Lycée, Lycée professionnel, Master, Licence, Doctorat, Collège, Lycée technique, École secondaire spécialisée, Diplôme universitaire de technologie, Diplôme universitaire en formation continue), MPA, MBA, EMBA)</li>
      </ol>
      </p>
    </li>
    <li>Chaque ligne dans le tableau sera considérée comme un segment.</li>
    </ul>`,
      picture: `
      <p>Les fichiers image sont supportés. Les vidéos arriveront bientôt.</p><p>
      Si l'image contient du texte, l'OCR est appliqué pour extraire le texte comme description.
      </p><p>
      Si le texte extrait par l'OCR n'est pas suffisant, un LLM visuel est utilisé pour obtenir les descriptions.
      </p>`,
      one: `
          <p>Les formats de fichiers supportés sont <b>DOCX, EXCEL, PDF, TXT</b>.
          </p><p>
          Pour un document, il sera considéré comme un seul segment, sans aucune découpe.
          </p><p>
          Si vous souhaitez résumer quelque chose qui nécessite tout le contexte d'un article et que la longueur du contexte du LLM sélectionné couvre la longueur du document, vous pouvez essayer cette méthode.
          </p>`,
      knowledgeGraph: `<p>Les formats de fichiers supportés sont <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML</b>
      <p>Après que les fichiers aient été découpés, ils utilisent les segments pour extraire le graphe de connaissances et la carte mentale du document entier. Cette méthode applique des façons naïves pour découper les fichiers :
      Le texte successif sera découpé en morceaux d'environ 512 tokens.</p>
      <p>Ensuite, les segments seront transmis au LLM pour extraire les nœuds et les relations d'un graphe de connaissances, et une carte mentale.</p>

      Attention au type d'entité que vous devez spécifier.</p>`,
      useRaptor: 'Utiliser RAPTOR pour améliorer la récupération',
      useRaptorTip:
        'Traitement Abstractive Récursif pour la Récupération Organisée en Arbre, veuillez consulter https://huggingface.co/papers/2401.18059',
      prompt: 'Invite',
      promptTip: 'Invite LLM utilisée pour la summarisation.',
      promptMessage: 'L’invite est requise',
      promptText: `Veuillez résumer les paragraphes suivants. Faites attention aux nombres, ne fabriquez pas de données. Paragraphes suivants :
            {cluster_content}
      Ce qui précède est le contenu que vous devez résumer.`,
      maxToken: 'Max tokens',
      maxTokenTip: 'Nombre maximum de tokens pour la summarisation.',
      maxTokenMessage: 'Le nombre maximum de tokens est requis',
      threshold: 'Seuil',
      thresholdTip: 'Plus le seuil est élevé, moins il y aura de clusters.',
      thresholdMessage: 'Le seuil est requis',
      maxCluster: 'Max clusters',
      maxClusterTip: 'Nombre maximum de clusters.',
      maxClusterMessage: 'Le nombre maximum de clusters est requis',
      randomSeed: 'Graine aléatoire',
      randomSeedMessage: 'La graine aléatoire est requise',
      entityTypes: 'Types d’entités',
      teamLabel: "Nom d'équipe",
      teamOptionsTip:
        "Choisissez l'équipe avec laquelles partager cette base de connaissance",
      teamOptionsPlaceholder: '',
    },
    teamList: {
      welcome: 'Bon retour',
      description: "Vous êtes sur la page de gestion d'équipe",
      createNewTeam: 'Créer une nouvelle équipe',
      name: 'Nom',
      namePlaceholder: 'Entrez un nom',
      teamAdmin: "Admin de l'équipe",
      adminPlaceholder: "Choisissez l'administrateur de l'équipe",
      doc: 'Documents',
      searchTeamsPlaceholder: 'Chercher',
      searchUserPlaceholder: 'Chercher',
      addUser: 'Ajouter un membre',
      addUserToTeam: "Ajouter un nouveau membre à l'équipe",
      user: 'Utilisateur',
      userPlaceHolder: "Choisissez l'utilisateur que vous souhaitez ajouter",
      role: 'Rôle',
      rolePlaceholder: 'Choisissez le rôle du nouveau membre',
      adminRole: 'Admin',
      ownerRole: 'Owner',
      normalRole: 'User',
      changeUserRole: "Changer le rôle de l'utilisateur",
    },
    chunk: {
      chunk: 'Segment',
      bulk: 'En masse',
      selectAll: 'Tout sélectionner',
      enabledSelected: 'Activer les sélectionnés',
      disabledSelected: 'Désactiver les sélectionnés',
      deleteSelected: 'Supprimer les sélectionnés',
      search: 'Recherche',
      all: 'Tout',
      enabled: 'Activé',
      disabled: 'Désactivé',
      keyword: 'Mot-clé',
      function: 'Fonction',
      chunkMessage: 'Veuillez entrer une valeur !',
      full: 'Texte complet',
      ellipse: 'Ellipse',
      graph: 'Graphe de connaissances',
      mind: 'Carte mentale',
    },
    chat: {
      newConversation: 'Nouvelle conversation',
      createAssistant: 'Créer un Assistant',
      assistantSetting: "Paramètre de l'Assistant",
      promptEngine: 'Moteur de Demande',
      modelSetting: 'Paramètre du Modèle',
      chat: 'Discussion',
      newChat: 'Nouvelle discussion',
      send: 'Envoyer',
      sendPlaceholder: "Message Résumer l'Assistant...",
      chatConfiguration: 'Configuration de la Discussion',
      chatConfigurationDescription:
        ' Ici, habillez un assistant dédié à vos bases de connaissances spéciales ! 💕',
      assistantName: "Nom de l'Assistant",
      assistantNameMessage: "Le nom de l'assistant est requis",
      namePlaceholder: 'par exemple, Resume Jarvis',
      assistantAvatar: "Avatar de l'Assistant",
      language: 'Langue',
      emptyResponse: 'Réponse vide',
      emptyResponseTip: `Si rien n'est récupéré avec la question de l'utilisateur dans la base de connaissances, cela sera utilisé comme réponse. Si vous souhaitez que le LLM donne son propre avis lorsque rien n'est récupéré, laissez ceci vide.`,
      setAnOpener: 'Définir une ouverture',
      setAnOpenerInitial: `Bonjour ! Je suis votre assistant, que puis-je faire pour vous ?`,
      setAnOpenerTip: 'Comment souhaitez-vous accueillir vos clients ?',
      knowledgeBases: 'Bases de Connaissances',
      knowledgeBasesMessage: 'Veuillez sélectionner',
      knowledgeBasesTip: 'Sélectionnez les bases de connaissances associées.',
      system: 'Système',
      systemInitialValue: `Vous êtes un assistant intelligent. Veuillez résumer le contenu de la base de connaissances pour répondre à la question. Veuillez lister les données dans la base de connaissances et répondre en détail. Lorsque tout le contenu de la base de connaissances est hors sujet pour la question, votre réponse doit inclure la phrase "La réponse que vous recherchez n'est pas trouvée dans la base de connaissances !" Les réponses doivent tenir compte de l'historique de la discussion.
      Voici la base de connaissances :
      {knowledge}
      Ce qui précède est la base de connaissances.`,
      systemMessage: 'Veuillez entrer !',
      systemTip:
        'Instructions que vous devez suivre lorsque le LLM répond à des questions, comme le design du personnage, la longueur de la réponse et la langue de la réponse, etc.',
      topN: 'Top N',
      topNTip: `Tous les morceaux dont le score de similarité est au-dessus du 'seuil de similarité' ne seront pas envoyés aux LLM. Le LLM ne peut voir que ces 'Top N' morceaux.`,
      variable: 'Variable',
      variableTip: `Si vous utilisez des API de dialogue, les variables peuvent vous aider à discuter avec vos clients avec différentes stratégies. 
      Les variables sont utilisées pour remplir la partie 'Système' dans l'invite afin de donner un indice au LLM.
      La 'connaissance' est une variable très spéciale qui sera remplie avec les morceaux récupérés.
      Toutes les variables dans 'Système' doivent être entre accolades.`,
      add: 'Ajouter',
      key: 'clé',
      optional: 'Optionnel',
      operation: 'opération',
      model: 'Modèle',
      modelTip: 'Modèle de chat de langage large',
      modelMessage: 'Veuillez sélectionner !',
      freedom: 'Liberté',
      improvise: 'Improviser',
      precise: 'Précis',
      balance: 'Équilibre',
      freedomTip: `'Précis' signifie que le LLM sera conservateur et répondra à votre question avec prudence. 'Improviser' signifie que vous voulez que le LLM parle beaucoup et librement. 'Équilibre' se situe entre la prudence et la liberté.`,
      temperature: 'Température',
      temperatureMessage: 'La température est requise',
      temperatureTip:
        "Ce paramètre contrôle l'aléa des prédictions du modèle. Une température plus basse rend le modèle plus sûr de ses réponses, tandis qu'une température plus élevée le rend plus créatif et diversifié.",
      topP: 'Top P',
      topPMessage: 'Top P est requis',
      topPTip:
        'Également connu sous le nom de "échantillonnage du noyau", ce paramètre fixe un seuil pour sélectionner un ensemble plus petit de mots à échantillonner. Il se concentre sur les mots les plus probables, en coupant les moins probables.',
      presencePenalty: 'Pénalité de Présence',
      presencePenaltyMessage: 'La Pénalité de Présence est requise',
      presencePenaltyTip:
        'Cela décourage le modèle de répéter les mêmes informations en pénalisant les mots qui ont déjà apparaître dans la conversation.',
      frequencyPenalty: 'Pénalité de Fréquence',
      frequencyPenaltyMessage: 'La Pénalité de Fréquence est requise',
      frequencyPenaltyTip:
        'Similaire à la pénalité de présence, cela réduit la tendance du modèle à répéter les mêmes mots fréquemment.',
      maxTokens: 'Max Tokens',
      maxTokensMessage: 'Max Tokens est requis',
      maxTokensTip:
        'Cela définit la longueur maximale de la sortie du modèle, mesurée en nombre de tokens (mots ou morceaux de mots).',
      quote: 'Afficher la Citation',
      quoteTip: 'La source du texte original doit-elle être affichée ?',
      selfRag: 'Self-RAG',
      selfRagTip:
        'Veuillez consulter : https://huggingface.co/papers/2310.11511',
      overview: 'API Chat Bot',
      pv: 'Nombre de messages',
      uv: "Nombre d'utilisateurs actifs",
      speed: 'Vitesse de sortie des tokens',
      tokens: 'Consommer le nombre de tokens',
      round: "Nombre d'Interactions de Session",
      thumbUp: 'satisfaction client',
      preview: 'Aperçu',
      embedded: 'Intégré',
      serviceApiEndpoint: "Point de terminaison de l'API du service",
      apiKey: 'Clé API',
      apiReference: 'Documents API',
      dateRange: 'Plage de Dates :',
      backendServiceApi: 'API de service backend',
      createNewKey: 'Créer une nouvelle clé',
      created: 'Créé',
      action: 'Action',
      embedModalTitle: 'Intégrer dans le site web',
      comingSoon: 'Bientôt disponible',
      fullScreenTitle: 'Intégration Complète',
      fullScreenDescription:
        "Intégrez l'iframe suivante dans votre site web à l'emplacement souhaité",
      partialTitle: 'Intégration Partielle',
      extensionTitle: 'Extension Chrome',
      tokenError: "Veuillez d'abord créer un Token API !",
      searching: 'recherche...',
      parsing: 'Analyse',
      uploading: 'Téléversement',
      uploadFailed: 'Échec du téléversement',
      regenerate: 'Régénérer',
      read: 'Lire le contenu',
      tts: 'Texte en parole',
      ttsTip:
        "Pour lire la voix en utilisant la conversion vocale, veuillez d'abord sélectionner TTS (modèle de conversion de parole) dans les paramètres.",
    },
    setting: {
      profile: 'Profil',
      profileDescription:
        'Mettez à jour votre photo et vos détails personnels ici.',
      password: 'Mot de passe',
      passwordDescription:
        'Veuillez entrer votre mot de passe actuel pour changer votre mot de passe.',
      model: 'Fournisseurs de Modèles',
      modelDescription: 'Définissez le paramètre du modèle et la clé API ici.',
      team: 'Équipe',
      system: 'Système',
      logout: 'Déconnexion',
      username: 'Nom d’utilisateur',
      usernameMessage: 'Veuillez entrer votre nom d’utilisateur !',
      photo: 'Votre photo',
      photoDescription: 'Cela sera affiché sur votre profil.',
      colorSchema: 'Schéma de couleurs',
      colorSchemaMessage: 'Veuillez sélectionner votre schéma de couleurs !',
      colorSchemaPlaceholder: 'sélectionnez votre schéma de couleurs',
      bright: 'Clair',
      dark: 'Sombre',
      timezone: 'Fuseau horaire',
      timezoneMessage: 'Veuillez entrer votre fuseau horaire !',
      timezonePlaceholder: 'sélectionnez votre fuseau horaire',
      email: 'Adresse e-mail',
      emailDescription:
        'Une fois enregistré, l’e-mail ne peut pas être modifié.',
      currentPassword: 'Mot de passe actuel',
      currentPasswordMessage: 'Veuillez entrer votre mot de passe !',
      newPassword: 'Nouveau mot de passe',
      newPasswordMessage: 'Veuillez entrer votre mot de passe !',
      newPasswordDescription:
        'Votre nouveau mot de passe doit comporter plus de 8 caractères.',
      confirmPassword: 'Confirmer le nouveau mot de passe',
      confirmPasswordMessage: 'Veuillez confirmer votre mot de passe !',
      confirmPasswordNonMatchMessage:
        'Le nouveau mot de passe que vous avez saisi ne correspond pas !',
      cancel: 'Annuler',
      addedModels: 'Modèles ajoutés',
      modelsToBeAdded: 'Modèles à ajouter',
      addTheModel: 'Ajouter le modèle',
      apiKey: 'Clé API',
      apiKeyMessage:
        'Veuillez entrer la clé API (pour le modèle déployé localement, ignorez ceci).',
      apiKeyTip:
        'La clé API peut être obtenue en enregistrant le fournisseur LLM correspondant.',
      showMoreModels: 'Afficher plus de modèles',
      baseUrl: 'URL de base',
      baseUrlTip:
        'Si votre clé API provient d’OpenAI, ignorez cela. Tout autre fournisseur intermédiaire vous donnera cette URL de base avec la clé API.',
      modify: 'Modifier',
      systemModelSettings: 'Paramètres du Modèle Système',
      chatModel: 'Modèle de discussion',
      chatModelTip:
        'Le modèle de chat par défaut que toutes les nouvelles bases de connaissances utiliseront.',
      embeddingModel: 'Modèle d’incorporation',
      embeddingModelTip:
        'Le modèle d’incorporation par défaut que toutes les nouvelles bases de connaissances utiliseront.',
      img2txtModel: 'Modèle Img2txt',
      img2txtModelTip:
        'Le modèle multi-modules par défaut que toutes les nouvelles bases de connaissances utiliseront. Il peut décrire une image ou une vidéo.',
      sequence2txtModel: 'Modèle Sequence2txt',
      sequence2txtModelTip:
        'Le modèle ASR par défaut que toutes les nouvelles bases de connaissances utiliseront. Utilisez ce modèle pour traduire les voix en texte correspondant.',
      rerankModel: 'Modèle de Rerank',
      rerankModelTip: `Le modèle de rerank par défaut est utilisé pour réorganiser les morceaux récupérés par les questions des utilisateurs.`,
      ttsModel: 'Modèle TTS',
      ttsModelTip:
        'Le modèle TTS par défaut sera utilisé pour générer la parole pendant les conversations sur demande.',
      workspace: 'Espace de travail',
      upgrade: 'Mettre à niveau',
      addLlmTitle: 'Ajouter LLM',
      modelName: 'Nom du modèle',
      modelID: 'ID du modèle',
      modelUid: 'UID du modèle',
      modelNameMessage: 'Veuillez entrer le nom de votre modèle !',
      modelType: 'Type de modèle',
      modelTypeMessage: 'Veuillez entrer le type de votre modèle !',
      addLlmBaseUrl: 'URL de base',
      baseUrlNameMessage: 'Veuillez entrer votre URL de base !',
      vision: 'Prend-il en charge la Vision ?',
      ollamaLink: 'Comment intégrer {{name}}',
      FishAudioLink: 'Comment utiliser FishAudio',
      TencentCloudLink: 'Comment utiliser TencentCloud ASR',
      volcModelNameMessage: 'Veuillez entrer le nom de votre modèle !',
      addEndpointID: 'EndpointID du modèle',
      endpointIDMessage: 'Veuillez entrer l’EndpointID de votre modèle',
      addArkApiKey: 'VOLC ARK_API_KEY',
      ArkApiKeyMessage: 'Veuillez entrer votre ARK_API_KEY',
      bedrockModelNameMessage: 'Veuillez entrer le nom de votre modèle !',
      addBedrockEngineAK: 'ACCESS KEY',
      bedrockAKMessage: 'Veuillez entrer votre ACCESS KEY',
      addBedrockSK: 'SECRET KEY',
      bedrockSKMessage: 'Veuillez entrer votre SECRET KEY',
      bedrockRegion: 'Région AWS',
      bedrockRegionMessage: 'Veuillez sélectionner !',
      'us-east-1': 'US East (N. Virginia)',
      'us-west-2': 'US West (Oregon)',
      'ap-southeast-1': 'Asie-Pacifique (Singapour)',
      'ap-northeast-1': 'Asie-Pacifique (Tokyo)',
      'eu-central-1': 'Europe (Francfort)',
      'us-gov-west-1': 'AWS GovCloud (US-West)',
      'ap-southeast-2': 'Asie-Pacifique (Sydney)',
      addHunyuanSID: 'Hunyuan Secret ID',
      HunyuanSIDMessage: 'Veuillez entrer votre Secret ID',
      addHunyuanSK: 'Hunyuan Secret Key',
      HunyuanSKMessage: 'Veuillez entrer votre Secret Key',
      addTencentCloudSID: 'TencentCloud Secret ID',
      TencentCloudSIDMessage: 'Veuillez entrer votre Secret ID',
      addTencentCloudSK: 'TencentCloud Secret Key',
      TencentCloudSKMessage: 'Veuillez entrer votre Secret Key',
      SparkModelNameMessage: 'Veuillez sélectionner le modèle Spark',
      addSparkAPIPassword: 'Spark APIPassword',
      SparkAPIPasswordMessage: 'veuillez entrer votre APIPassword',
      yiyanModelNameMessage: 'Veuillez entrer le nom du modèle',
      addyiyanAK: 'yiyan API KEY',
      yiyanAKMessage: 'Veuillez entrer votre API KEY',
      addyiyanSK: 'yiyan Secret KEY',
      yiyanSKMessage: 'Veuillez entrer votre Secret KEY',
      FishAudioModelNameMessage:
        'Veuillez donner un nom à votre modèle de synthèse vocale',
      addFishAudioAK: 'Fish Audio API KEY',
      addFishAudioAKMessage: 'Veuillez entrer votre API KEY',
      addFishAudioRefID: 'FishAudio Refrence ID',
      addFishAudioRefIDMessage:
        'Veuillez entrer l’ID de Référence (laisser vide pour utiliser le modèle par défaut).',
      GoogleModelIDMessage: 'Veuillez entrer l’ID de votre modèle !',
      addGoogleProjectID: 'ID du Projet',
      GoogleProjectIDMessage: 'Veuillez entrer l’ID de votre Projet',
      addGoogleServiceAccountKey:
        "Clé de Compte de Service (laisser vide si vous utilisez les Identifiants Par Défaut d'Application)",
      GoogleServiceAccountKeyMessage:
        'Veuillez entrer la Clé de Compte de Service Google Cloud au format base64',
      addGoogleRegion: 'Région Google Cloud',
      GoogleRegionMessage: 'Veuillez entrer la Région Google Cloud',
    },
    message: {
      registered: 'Enregistré !',
      logout: 'Déconnexion',
      logged: 'Connecté !',
      pleaseSelectChunk: 'Veuillez sélectionner un morceau !',
      modified: 'Modifié',
      created: 'Créé',
      deleted: 'Supprimé',
      renamed: 'Renommé',
      operated: 'Opéré',
      updated: 'Mis à jour',
      uploaded: 'Téléversé',
      200: 'Le serveur renvoie avec succès les données demandées.',
      201: 'Données créées ou modifiées avec succès.',
      202: 'Une demande a été mise en file d’attente en arrière-plan (tâche asynchrone).',
      204: 'Données supprimées avec succès.',
      400: 'Il y a eu une erreur dans la demande émise, et le serveur n’a pas créé ou modifié les données.',
      401: 'L’utilisateur n’a pas les autorisations (mauvais jeton, nom d’utilisateur, mot de passe).',
      403: 'L’utilisateur est autorisé, mais l’accès est interdit.',
      404: 'La demande a été faite pour un enregistrement qui n’existe pas, et le serveur n’a pas effectué l’opération.',
      406: 'Le format demandé n’est pas disponible.',
      410: 'La ressource demandée a été définitivement supprimée et ne sera plus disponible.',
      422: 'Lors de la création d’un objet, une erreur de validation est survenue.',
      500: 'Une erreur de serveur est survenue, veuillez vérifier le serveur.',
      502: 'Erreur de passerelle.',
      503: 'Le service est indisponible et le serveur est temporairement surchargé ou en maintenance.',
      504: 'Délai d’attente de la passerelle.',
      requestError: 'Erreur de demande',
      networkAnomalyDescription:
        'Il y a une anomalie dans votre réseau et vous ne pouvez pas vous connecter au serveur.',
      networkAnomaly: 'anomalie réseau',
      hint: 'indice',
    },
    fileManager: {
      name: 'Nom',
      uploadDate: 'Date de téléversement',
      knowledgeBase: 'Base de connaissances',
      size: 'Taille',
      action: 'Action',
      addToKnowledge: 'Lier à la Base de connaissances',
      pleaseSelect: 'Veuillez sélectionner',
      newFolder: 'Nouveau dossier',
      file: 'Fichier',
      uploadFile: 'Téléverser un fichier',
      directory: 'Répertoire',
      uploadTitle:
        'Cliquez ou faites glisser le fichier dans cette zone pour le téléverser',
      uploadDescription:
        'Support pour un téléversement unique ou en masse. Il est strictement interdit de téléverser des données d’entreprise ou d’autres fichiers interdits.',
      local: 'Téléversements locaux',
      s3: 'Téléversements S3',
      preview: 'Aperçu',
      fileError: 'Erreur de fichier',
      uploadLimit:
        'La taille du fichier ne peut pas dépasser 10 Mo, et le nombre total de fichiers ne peut pas dépasser 128',
      destinationFolder: 'Dossier de destination',
    },
    flow: {
      nodata: 'Pas de données',
      cite: 'Citer',
      citeTip: 'Conseil de citation',
      name: 'Nom',
      nameMessage: 'Veuillez entrer un nom',
      description: 'Description',
      examples: 'Exemples',
      to: 'À',
      msg: 'Messages',
      messagePlaceholder: 'message',
      messageMsg: 'Veuillez entrer un message ou supprimer ce champ.',
      addField: 'Ajouter un champ',
      loop: 'Boucle',
      loopTip:
        'La boucle est la limite supérieure du nombre de boucles du composant actuel. Lorsque le nombre de boucles dépasse la valeur de boucle, cela signifie que le composant ne peut pas terminer la tâche actuelle. Veuillez réoptimiser l’agent.',
      yes: 'Oui',
      no: 'Non',
      key: 'Clé',
      componentId: 'ID du composant',
      add: 'Ajouter',
      operation: 'Opération',
      run: 'Exécuter',
      save: 'Enregistrer',
      title: 'Titre :',
      beginDescription: 'C’est ici que le flux commence',
      answerDescription: `Ce composant est utilisé comme interface entre le bot et l’utilisateur. Il reçoit les entrées de l’utilisateur et affiche le résultat du calcul du bot.`,
      retrievalDescription: `Ce composant est utilisé pour récupérer des informations pertinentes à partir de la base de connaissances. Les bases de connaissances doivent être sélectionnées. Si rien n'est récupéré, la réponse 'Vide' sera retournée.`,
      generateDescription: `Ce composant est utilisé pour appeler LLM afin de générer du texte. Faites attention aux paramètres du prompt.`,
      categorizeDescription: `Ce composant est utilisé pour catégoriser le texte. Veuillez spécifier le nom, la description et les exemples de la catégorie. Chaque catégorie entraîne des composants en aval différents.`,
      relevantDescription: `Ce composant est utilisé pour juger si la sortie en amont est pertinente pour la question la plus récente de l’utilisateur. 'Oui' signifie qu'ils sont pertinents. 'Non' signifie qu'ils ne sont pas pertinents.`,
      rewriteQuestionDescription: `Ce composant est utilisé pour affiner la question de l’utilisateur. En général, lorsque la question originale d'un utilisateur ne peut pas récupérer d'informations pertinentes à partir de la base de connaissances, ce composant vous aide à modifier la question en une question appropriée qui pourrait être plus cohérente avec les expressions de la base de connaissances. Seul 'Récupération' peut être ses composants en aval.`,
      messageDescription:
        'Ce composant est utilisé pour envoyer des informations statiques à l’utilisateur. Vous pouvez préparer plusieurs messages qui seront choisis aléatoirement.',
      keywordDescription: `Ce composant est utilisé pour extraire des mots-clés de la question de l’utilisateur. Top N spécifie le nombre de mots-clés que vous devez extraire.`,
      wikipediaDescription: `Ce composant est utilisé pour obtenir des résultats de recherche à partir de https://www.wikipedia.org/. En général, il complète les bases de connaissances. Top N spécifie le nombre de résultats de recherche que vous devez adapter.`,
      promptText: `Veuillez résumer les paragraphes suivants. Faites attention aux chiffres, ne créez pas de choses. Paragraphes comme suit :
        {input}
  Ce qui précède est le contenu que vous devez résumer.`,
      createGraph: 'Créer un agent',
      createFromTemplates: 'Créer à partir de modèles',
      retrieval: 'Récupération',
      generate: 'Générer',
      answer: 'Répondre',
      categorize: 'Catégoriser',
      relevant: 'Pertinent',
      rewriteQuestion: 'Réécrire',
      rewrite: 'Réécrire',
      begin: 'Commencer',
      message: 'Message',
      blank: 'Vide',
      createFromNothing: 'Créer à partir de rien',
      addItem: 'Ajouter un élément',
      addSubItem: 'Ajouter un sous-élément',
      nameRequiredMsg: 'Le nom est requis',
      nameRepeatedMsg: 'Le nom ne peut pas être répété',
      keywordExtract: 'Mot-clé',
      keywordExtractDescription: `Ce composant est utilisé pour extraire des mots-clés de la question de l’utilisateur. Top N spécifie le nombre de mots-clés que vous devez extraire.`,
      baidu: 'Baidu',
      baiduDescription: `Ce composant est utilisé pour obtenir des résultats de recherche à partir de www.baidu.com. En général, il complète les bases de connaissances. Top N spécifie le nombre de résultats de recherche que vous devez adapter.`,
      duckDuckGo: 'DuckDuckGo',
      duckDuckGoDescription:
        'Ce composant est utilisé pour obtenir des résultats de recherche à partir de www.duckduckgo.com. En général, il complète les bases de connaissances. Top N spécifie le nombre de résultats de recherche que vous devez adapter.',
      channel: 'Canal',
      channelTip: `Effectuez une recherche de texte ou de nouvelles sur l’entrée du composant`,
      text: 'Texte',
      news: 'Actualités',
      messageHistoryWindowSize:
        'Taille de la fenêtre de l’historique des messages',
      messageHistoryWindowSizeTip:
        'La taille de la fenêtre de l’historique des conversations nécessaire pour être vue par LLM. Plus c’est grand, mieux c’est. Mais faites attention à la longueur maximale du contenu de LLM.',
      wikipedia: 'Wikipedia',
      pubMed: 'PubMed',
      email: 'Email',
      emailTip:
        'Ce composant est utilisé pour obtenir des résultats de recherche à partir de https://pubmed.ncbi.nlm.nih.gov/. En général, il complète les bases de connaissances. Top N spécifie le nombre de résultats de recherche que vous devez adapter. L’email est un champ obligatoire.',
      arXiv: 'ArXiv',
      arXivTip:
        'Ce composant est utilisé pour obtenir des résultats de recherche à partir de https://arxiv.org/. En général, il complète les bases de connaissances. Top N spécifie le nombre de résultats de recherche que vous devez adapter.',
      sortBy: 'Trier par',
      submittedDate: 'Date de soumission',
      lastUpdatedDate: 'Dernière date de mise à jour',
      relevance: 'Pertinence',
      google: 'Google',
      googleTip:
        'Ce composant est utilisé pour obtenir des résultats de recherche à partir de https://www.google.com/. En général, il complète les bases de connaissances. Top N et la clé API SerpApi spécifient le nombre de résultats de recherche que vous devez adapter.',
      bing: 'Bing',
      bingTip:
        'Ce composant est utilisé pour obtenir des résultats de recherche à partir de https://www.bing.com/. En général, il complète les bases de connaissances. Top N et la clé de souscription Bing spécifient le nombre de résultats de recherche que vous devez adapter.',
      apiKey: 'Clé API',
      country: 'Pays',
      language: 'Langue',
      googleScholar: 'Google Scholar',
      googleScholarDescription:
        'Ce composant est utilisé pour obtenir des résultats de recherche à partir de https://scholar.google.com/. En général, il complète les bases de connaissances. Top N spécifie le nombre de résultats de recherche que vous devez adapter.',
      yearLow: 'Année basse',
      yearHigh: 'Année haute',
      patents: 'Brevets',
      data: 'Données',
      deepL: 'DeepL',
      deepLDescription:
        'Ce composant est utilisé pour obtenir des traductions à partir de https://www.deepl.com/. En général, il fournit un résultat de traduction plus spécialisé.',
      authKey: 'Clé d’authentification',
      sourceLang: 'Langue source',
      targetLang: 'Langue cible',
      gitHub: 'GitHub',
      githubDescription:
        'Ce composant est utilisé pour rechercher dans le dépôt à partir de https://github.com/. Top N spécifie le nombre de résultats de recherche à ajuster.',
      baiduFanyi: 'BaiduFanyi',
      baiduFanyiDescription:
        'Ce composant est utilisé pour obtenir des traductions à partir de https://fanyi.baidu.com/. En général, il fournit un résultat de traduction plus spécialisé.',
      appid: 'ID de l’application',
      secretKey: 'Clé secrète',
      domain: 'Domaine',
      transType: 'Type de traduction',
      baiduSecretKeyOptions: {
        translate: 'Traduction générale',
        fieldtranslate: 'Traduction spécialisée',
      },
      baiduDomainOptions: {
        it: 'Technologies de l’information',
        finance: 'Finances et économie',
        machinery: 'Fabrication de machines',
        senimed: 'Biomédecine',
        novel: 'Littérature en ligne',
        academic: 'Article académique',
        aerospace: 'Aérospatiale',
        wiki: 'Sciences humaines et sociales',
        news: 'Actualités et informations',
        law: 'Lois et règlements',
        contract: 'Contrat',
      },
      baiduSourceLangOptions: {
        auto: 'Détecter automatiquement',
        zh: 'Chinois',
        en: 'Anglais',
        yue: 'Cantonais',
        wyw: 'Chinois classique',
        jp: 'Japonais',
        kor: 'Coréen',
        fra: 'Français',
        spa: 'Espagnol',
        th: 'Thaï',
        ara: 'Arabe',

        ru: 'Russe',
        pt: 'Portugais',
        de: 'Allemand',
        it: 'Italien',
        el: 'Grec',
        nl: 'Néerlandais',
        pl: 'Polonais',
        bul: 'Bulgare',
        est: 'Estonien',
        dan: 'Danois',
        fin: 'Finnois',
        cs: 'Tchèque',
        rom: 'Roumain',
        slo: 'Slovène',
        swe: 'Suédois',
        hu: 'Hongrois',
        cht: 'Chinois traditionnel',
        vie: 'Vietnamien',
        qWeather: 'QWeather',
        qWeatherDescription:
          "Ce composant est utilisé pour obtenir des informations météorologiques à partir de https://www.qweather.com/. Vous pouvez obtenir des informations sur la météo, les indices, la qualité de l'air.",
        lang: 'Langue',
        type: 'Type',
        webApiKey: 'Clé API Web',
        userType: 'Type d’utilisateur',
        timePeriod: 'Période',
        qWeatherLangOptions: {
          zh: 'Chinois simplifié',
          'zh-hant': 'Chinois traditionnel',
          en: 'Anglais',
          de: 'Allemand',
          es: 'Espagnol',
          fr: 'Français',
          it: 'Italien',
          ja: 'Japonais',
          ko: 'Coréen',
          ru: 'Russe',
          hi: 'Hindi',
          th: 'Thaï',
          ar: 'Arabe',
          pt: 'Portugais',
          bn: 'Bengali',
          ms: 'Malais',
          nl: 'Néerlandais',
          el: 'Grec',
          la: 'Latin',
          sv: 'Suédois',
          id: 'Indonésien',
          pl: 'Polonais',
          tr: 'Turc',
          cs: 'Tchèque',
          et: 'Estonien',
          vi: 'Vietnamien',
          fil: 'Filipino',
          fi: 'Finnois',
          he: 'Hébreu',
          is: 'Islandais',
          nb: 'Norvégien',
        },
        qWeatherTypeOptions: {
          weather: 'Prévisions météorologiques',
          indices: 'Indice de vie météorologique',
          airquality: 'Qualité de l’air',
        },
        qWeatherUserTypeOptions: {
          free: 'Abonné gratuit',
          paid: 'Abonné payant',
        },
        qWeatherTimePeriodOptions: {
          now: 'Maintenant',
          '3d': '3 jours',
          '7d': '7 jours',
          '10d': '10 jours',
          '15d': '12 jours',
          '30d': '30 jours',
        },
        publish: 'API',
        exeSQL: 'ExeSQL',
        exeSQLDescription:
          'Le composant interroge les résultats de la base de données relationnelle correspondante via des instructions SQL. Supporte MySQL, PostgreSQL, MariaDB.',
        dbType: 'Type de base de données',
        database: 'Base de données',
        username: 'Nom d’utilisateur',
        host: 'Hôte',
        port: 'Port',
        password: 'Mot de passe',
        switch: 'Interrupteur',
        logicalOperator: 'Opérateur logique',
        switchOperatorOptions: {
          equal: 'égal',
          notEqual: 'non égal',
          gt: 'Supérieur à',
          ge: 'Supérieur ou égal',
          lt: 'Inférieur à',
          le: 'Inférieur ou égal',
          contains: 'Contient',
          notContains: 'Ne contient pas',
          startWith: 'Commence par',
          endWith: 'Se termine par',
          empty: 'Vide',
          notEmpty: 'Non vide',
        },
        switchLogicOperatorOptions: {
          and: 'Et',
          or: 'Ou',
        },
        operator: 'Opérateur',
        value: 'Valeur',
        useTemplate: 'Utiliser ce modèle',
      },
      footer: {
        profile: 'Tous droits réservés @ React',
      },
      layout: {
        file: 'fichier',
        knowledge: 'connaissance',
        chat: 'discussion',
      },
    },
  },
};
